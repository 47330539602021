@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

// Styles
.PartyOwlbert {
  &-modal {
    background: none;
    box-shadow: none;
  }

  &-image {
    height: auto;
    max-width: 75%;
  }

  &-image-pop {
    animation: pop 0.2s ease-out;
    height: auto;
    margin-top: 15px;
    max-width: 75%;

    @keyframes pop {
      100% {
        margin-top: 15px;
      }

      80% {
        margin-top: 0;
      }

      0% {
        margin-top: 40px;
      }
    }
  }

  &-container {
    padding-bottom: 20px;
    padding-top: 45px;
    position: relative;
  }

  &-banner {
    background: url('./banner.png') top right no-repeat;
    background-size: contain;
    display: flex;

    // font-size: 25px;
    height: 100px;
    justify-content: center;
    margin: auto;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &-text {
      color: var(--white);
      font-size: 30px;
      padding-bottom: 45px;
      text-shadow: -1px 2px var(--gray10);
    }
  }

  &-status {
    align-items: center;
    background: white;
    border-radius: var(--border-radius);
    box-shadow:
      0 5px 10px rgba(0, 0, 0, 0.1),
      0 1px 5px rgba(0, 0, 0, 0.05);
    color: black;
    display: inline-flex;
    font-family: var(--font-family-mono);
    font-size: 12px;
    gap: 0.5rem;
    justify-content: space-around;
    margin-top: -30px;
    padding: 0.6rem;
  }

  &-httpStatus {
    align-items: center;
    display: inline-flex;
    font-family: var(--font-family-mono);

    &-chit {
      background: var(--green);
      border: 1px solid rgba(black, 0.15);
      border-radius: var(--sm);
      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.5),
        inset 0 -1px 2px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.1);
      height: var(--sm);
      width: var(--sm);
    }
  }

  &-wrapper {
    background: none;
  }
}
