@import '~@core/styles/vars';

.Spinner {
  --spinner-color: var(--gray70);

  align-items: center;
  display: flex;
  justify-content: center;

  &_sm {
    font-size: var(--icon-sm);
  }

  &_md {
    font-size: var(--icon-md);
  }

  &_lg {
    font-size: var(--icon-lg);
  }

  &_xl {
    font-size: calc(var(--icon-md) * 2);
  }

  &_xxl {
    font-size: calc(var(--icon-md) * 3);
  }

  &-icon {
    animation: spin 1s infinite linear;
    color: var(--spinner-color);
    fill: none;
    height: 1em;
    overflow: visible;
    width: 1em;

    @keyframes spin {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(1turn);
      }
    }

    @media (prefers-reduced-motion) {
      display: none;
    }
  }

  // Falls back to a 'Loading...' text label when user prefers reduced motion
  &-label {
    display: none;

    @media (prefers-reduced-motion) {
      display: block;
    }
  }
}
