@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.ReactTable {
  --Table-hover-bg: #{rgba(black, 0.02)};
  --Table-loading-minheight: 200px;

  @include dark-mode {
    --Table-hover-bg: #{rgba(white, 0.05)};
  }

  $block: &;

  height: auto; // Allow the table to size to its content
  width: 100%;
  
  &-table-outer-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--md);
    height: 100%;
    width: 100%;
  }

  &-table-with-card {
    padding: 0;
    
    #{$block}-columnSelector {
      right: var(--sm);
    }
  }

  &-table-compact {
    #{$block}-td {
      padding: var(--xs) var(--sm);
    }
  }

  &-table-loading-or-empty {
    align-items: center;
    color: var(--color-text-muted);
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    min-height: var(--Table-loading-minheight);
  }

  &-table-empty-text {
    color: var(--red);
  }

  &-table-wrapper {
    flex: 1;
    overflow-x: auto;
    position: relative;
  }

  &-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;

    &_fixed {
      table-layout: fixed;
    }

    &_auto {
      table-layout: auto;
    }
  }

  &-columnSelector {
    position: absolute;
    right: var(--xs);
    text-transform: none;
    top: var(--sm);
    z-index: 2;

    &-btn {
      @include dark-mode {
        background: var(--gray10);

        &:hover {
          background: var(--Table-hover-bg);
        }
      }

      background: rgba($gray100, 0.9);

      &:hover {
        background-color: var(--Table-hover-bg);
      }
    }

    &-label {
      flex: 1;

      :global(.Toggle-display) {
        flex: none;
      }

      :global(.Toggle-input) {
        cursor: pointer;
      }

      :global(.Toggle-label) {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &_dark-on-black {
      @include dark-mode {
        background: transparent;
      }

      background: transparent;
    }
  }

  &-thead {
    @include dark-mode {
      background: var(--gray10);
    }

    background: rgba($gray100, 0.9);

    :global {
      tr th {
        box-shadow: inset 0 -1px 0 var(--color-border-default);
      }

      // give some padding to last column so that the column selector doesn't overlap
      th:nth-last-child(2) {
        padding-right: 30px;
      }
    }

    &-transparent {
      @include dark-mode {
        background: transparent;
      }

      background: transparent;

      #{$block}-columnSelector {
        background-color: transparent;

        &-btn {
          background-color: transparent;
        }
      }
    }

    &-dark-on-black {
      @include dark-mode {
        background: transparent;
      }

      background: transparent;
    }
  }

  &-tr + :global(tr:not([class*='ReactTable-tr-sub-component'])) :global(td) {
    border-top: 1px solid var(--color-border-default);
  }

  &-th,
  &-td {
    height: 20px;
    padding: var(--sm);
  }

  &-th:focus,
  &-th:focus-visible {
    outline-offset: -1px;
  }

  tbody &-tr-clickable:focus,
  tbody &-tr-clickable:focus-visible {
    &:not(#{$block}-tr-highlight) {
      background: var(--Table-hover-bg);
      outline-offset: -1px;
    }
  }

  tbody &-tr-clickable:hover {
    &:not(#{$block}-tr-highlight) {
      background: var(--Table-hover-bg);
      cursor: pointer;
    }
  }

  &-tr-highlight {
    background-color: rgba($blue, 0.05);

    td {
      color: $blue;
    }
  }

  &-tr-sub-component {
    td {
      color: black !important;
    }
  }

  &-th {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;

    &-columnSelector {
      padding: 0;
      position: relative;
      width: 0;

      &_dark-on-black button {
        @include dark-mode {
          background: transparent !important;
        }

        background: transparent !important;
      }
    }

    &-sort {
      :global(.Icon) {
        flex-shrink: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-td {
    color: var(--color-text-muted);
    font-size: 14px;
    font-weight: var(--font-weight-normal);

    // Always truncate table cells
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    word-wrap: break-word;

    &-overflow {
      overflow: visible;
      text-overflow: initial;
      white-space: initial;
    }

    &-empty {
      color: var(--gray80);
    }

    &-company-avatar {
      flex-shrink: 0;
    }

    &-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      word-wrap: break-word;
    }

    &-copyable {
      flex: 1;

      &-clipboard {
        display: none;
        height: auto;
      }

      &:hover &-clipboard {
        border: 0;
        display: inline-flex;
      }
    }

    &-method {
      align-items: center;
      display: flex;
      gap: var(--xs);
    }
  }

  // we don't want to have ellipses for our sub components, reverts the parent compoenent setting
  &-td-sub-component {
    text-overflow: revert;
  }

  &-pagination {
    margin-top: auto !important;
    width: 100%;
  }
}
