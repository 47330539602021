@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

@mixin button($color) {
  $root: #{&};
  $dark: scale-color($color, $lightness: -10%);
  $darker: scale-color($color, $lightness: -20%);
  $lighter: scale-color($color, $lightness: +20%);
  $disabled: scale-color($color, $lightness: +50%);
  $text: if(lightness($color) > 66, black, white);
  $active: rgba(mix($text, $color, 70%), 0.9);

  background: $color;
  border: 1px solid rgba(black, 0.1);
  color: $text;
  font-family: var(--font-family);

  & {
    --button-spinner-color: #{$text};
  }

  &:hover {
    background: $dark;
    color: $text;
  }

  &:active {
    background: $darker;
  }

  &:active,
  &:focus-visible {
    box-shadow: 0 0 0 3px rgba($color, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover,
    &:active {
      background: $color;
    }

    &:active,
    &:focus-visible {
      box-shadow: none;
    }
  }

  &_outline,
  &_ghost,
  &_text {
    --button-spinner-color: #{$color};
  }

  &_outline {
    @include dark-mode {
      background: transparent;
      border-color: var(--color-border-default);

      @if $color == $graphite {
        color: var(--color-text-muted);
      }

      @if $color == $minimum-gray {
        color: var(--color-text-minimum);
      }
    }

    background: $white;
    color: $color;

    @if $color == $gray90 {
      background: transparent;
      border-color: rgba(white, 0.1);
    }
  }

  &_ghost {
    @include dark-mode {
      @if $color == $graphite {
        color: var(--color-text-muted);
      }

      @if $color == $minimum-gray {
        color: var(--color-text-minimum);
      }
    }

    background: none;
    border: 1px solid transparent;
    color: $color;
  }

  &_outline,
  &_ghost {
    &:hover,
    &:active,
    &:disabled {
      background: none;
    }

    &:disabled {
      &:hover,
      &:active {
        background: none;
      }
    }

    &:not(:disabled) {
      &:hover {
        @include dark-mode {
          border-color: rgba(white, 0.2);

          @if $color == $graphite {
            color: var(--gray70);
          }

          @if $color == $minimum-gray {
            color: var(--gray60);
          }
        }

        border: 1px solid rgba(black, 0.2);
        color: $dark;

        @if $color == $gray90 {
          color: $lighter;
          border-color: rgba(white, 0.2);
        }
      }

      &:active,
      &:focus-visible {
        @include dark-mode {
          border-color: currentcolor;

          @if $color != $red and $color != $yellow and $color !=$gray90 {
            border-color: $blue;
          }

          @if $color == $gray90 {
            border-color: $darker;
          }
        }

        border-color: currentcolor;

        @if $color != $red and $color != $yellow and $color != $gray90 {
          border-color: $blue;
          box-shadow: 0 0 0 3px rgba($blue, 0.25);
        }

        @if $color == $gray90 {
          border-color: $darker;
        }
      }
    }

    &:disabled {
      color: $disabled;
    }
  }

  &_text {
    @include dark-mode {
      @if $color == $graphite {
        color: var(--color-text-muted);
      }

      @if $color == $minimum-gray {
        color: var(--color-text-minimum);
      }
    }

    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: $color;

    &:hover,
    &:active,
    &:disabled {
      background: none;
      border-color: transparent;
    }

    &:disabled {
      &:hover,
      &:active {
        background: none;
      }
    }

    &:not(:disabled) {
      &:hover {
        @include dark-mode {
          @if $color == $graphite {
            color: var(--gray70);
          }

          @if $color == $minimum-gray {
            color: var(--gray60);
          }
        }

        color: $darker;
      }

      &:active,
      &:focus-visible {
        @include dark-mode {
          border-color: currentcolor;

          @if $color != $red and $color != $yellow and $color !=$gray90 {
            border-color: $blue;
          }

          @if $color == $graphite {
            color: var(--gray60);
          }

          @if $color == $minimum-gray {
            color: var(--gray50);
          }

          @if $color == $gray90 {
            border-color: $darker;
          }
        }

        border-color: currentcolor;
        color: $darker;

        @if $color != $red and $color != $yellow and $color != $gray90 {
          border-color: $blue;
          box-shadow: 0 0 0 3px rgba($blue, 0.25);
        }
      }
    }

    &:disabled {
      color: $disabled;
    }
  }

  &_link {
    background: none;
    border: none;
    border-radius: 0;
    color: $color;
    cursor: pointer;
    font-size: 1em;
    height: unset;
    padding: 0;
    text-decoration: underline;

    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus-visible {
      background: none;
      border: none;
      color: $darker;
      text-decoration: none;
    }
  }

  @if $color == white {
    $disabled: rgba($color, 0.5);
  }
}

.Button {
  --button-icon-offset: -4px;

  align-items: center;
  appearance: none;
  border: none;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font: inherit;
  font-size: var(--button-font-size);
  font-weight: var(--font-weight-bold);
  height: 40px;
  justify-content: center;
  padding: 0 15px;
  transition:
    var(--transition-fast) var(--transition-timing) background-color,
    var(--transition-fast) var(--transition-timing) border,
    var(--transition-slow) var(--transition-timing) box-shadow;
  white-space: nowrap;

  // Explicitly reinstate this native style b/c we're overriding the `display`
  // rule for the button to be `inline-flex`.
  &[hidden] {
    display: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus-visible {
    outline: none;
  }

  > span {
    min-width: 0;
  }

  // Adds spacer between all child elements.
  > *:not(.Button-spinner) + * {
    margin-left: var(--button-gap);
  }

  > .IconWrapper,
  > i {
    flex: 0 0 auto;
    font-size: calc(var(--button-font-size) + 2px);
  }

  > .IconWrapper:only-child,
  > i:only-child {
    margin: 0 var(--button-icon-offset);
  }

  // Applies styles to the SVG or legacy icon before the button label. Sometimes
  // we render a button spinner as the first child instead of the icon. So we
  // have to include additional sibling selectors in that case.
  > .IconWrapper:not(:only-child):first-child,
  > i.icon:not(:only-child):first-child,
  > .Button-spinner + .IconWrapper,
  > .Button-spinner + i.icon {
    margin-left: var(--button-icon-offset);
    opacity: 0.6;
  }

  // Applies spacing to the SVG or legacy icon directly after the button label.
  > .Button-label + .IconWrapper,
  > .Button-label + i.icon {
    margin-right: var(--button-icon-offset);
    opacity: 0.6;
  }

  &_full-width {
    width: 100%;
  }

  &_uppercase {
    font-size: calc(var(--button-font-size) - 1px);
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &_xs {
    --button-font-size: 13px;
    --button-gap: 4px;

    height: 20px;
    padding: 0 6px;

    &.Button_circular {
      border-radius: 30px;
    }
  }

  &_sm {
    --button-font-size: 14px;
    --button-gap: 4px;

    height: 30px;
    padding: 0 10px;

    &.Button_circular {
      border-radius: 30px;
    }
  }

  &_md {
    --button-font-size: 16px;
    --button-gap: 6px;

    // Other size styles use default

    &.Button_circular {
      border-radius: 40px;
    }
  }

  &_lg {
    --button-font-size: 18px;
    --button-gap: 8px;

    height: 50px;
    padding: 0 18px;

    &.Button_circular {
      border-radius: 50px;
    }
  }

  &_primary {
    @include button($blue);
  }

  &_secondary {
    @include button($graphite);
  }

  &_destructive {
    @include button($red);
  }

  &_warning {
    @include button($yellow);
  }

  &_success {
    @include button($green);
  }

  &_minimum {
    @include button($minimum-gray);
  }

  &_contrast {
    @include button($gray90);

    &:disabled {
      opacity: 0.5;
    }
  }

  // Keep the named color modifiers to support the old bem prop
  @each $k, $v in get(color) {
    &_#{$k} {
      @include button($v);

      &_darker {
        $darker: scale-color($v, $lightness: -33%);
        $darker: saturate($darker, 20%);

        @include button($darker);
      }
    }
  }

  @at-root a#{&} {
    text-decoration: none;
  }

  .Button-spinner {
    --spinner-color: var(--button-spinner-color);
  }

  &_loading:not(.Button_loading_withText) {
    &,
    &:hover,
    &:active,
    &:focus-visible {
      color: transparent;
    }

    > * {
      opacity: 0;
    }

    .Button-spinner {
      opacity: 1;
      position: absolute;
    }
  }

  &_loading_withText .Button-spinner {
    margin-right: 0.5em;

    @media (prefers-reduced-motion) {
      display: none;
    }
  }

  &-spinner {
    display: inline-flex;
  }
}
