@import '../../../../../core/styles/vars.compat';

.TableCell {
  border-bottom: none !important;
  border-right: none !important;

  &:first-child {
    border-left: none;
  }

  &_Header {
    border-top: none !important;
  }

  &_Placeholder {
    float: left;
    margin-right: -100%;
    opacity: 0.333;
    pointer-events: none;
    user-select: none;
  }
}
