/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.Menu {
  --Menu-background-active: var(--menu-background-active, var(--color-bg-active));
  --Menu-background-hover: var(--menu-background-hover, var(--color-bg-hover));
  --Menu-border-shadow: none;
  --Menu-border: #{rgba(black, 0.05)};
  --Menu-description-color: var(--color-text-minimum);
  --Menu-header: var(--menu-header, var(--gray40));
  --Menu-item: var(--menu-item, var(--gray20));

  @mixin menu-dark-mode {
    --Menu-border: #{rgba(white, 0.05)};
    --Menu-border-shadow: var(--color-bg-hover);
    --Menu-header: var(--gray80);
    --Menu-item: var(--white);
  }

  @include dark-mode {
    @include menu-dark-mode;
  }

  display: flex;
  flex-direction: column;
  max-width: 275px;
  min-width: 200px;
  overflow: visible;
  padding: 5px 0;
  position: relative;

  // via Menu prop
  &_dark {
    --Menu-description-color: var(--gray70);

    @include menu-dark-mode;
  }

  &-Header {
    color: var(--Menu-header);
    font-size: 12px;
    font-weight: var(--font-weight);
    margin-bottom: var(--xs);
    margin-top: var(--sm);
    padding-left: var(--sm);
    padding-right: var(--sm);
    word-break: break-word;

    &:first-of-type {
      margin-top: 3px;
    }
  }

  &-Divider {
    background: var(--Menu-border);
    box-shadow: 0 1px 0 var(--Menu-border-shadow);
    flex: 0 0 auto;
    height: 1px;
    margin: ms(-8) 0;

    &:last-child {
      display: none;
    }
  }

  &-Item {
    align-items: center;
    box-sizing: border-box;
    color: var(--Menu-item);
    display: flex;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-weight);
    margin: 0;
    padding: var(--xs) var(--sm);
    position: relative;
    width: auto;

    .IconWrapper,
    &.icon {
      font-size: 14px;
      opacity: 0.5;
      width: 14px;
    }

    &-alignIcon_left {
      > * {
        flex: 1 1 auto;
        width: 100%;

        .IconWrapper,
        &.icon {
          flex: 0 0;

          &:first-child {
            margin-right: var(--sm);
          }
        }
      }
    }

    &-alignIcon_right > *:last-child {
      margin-left: auto;
    }

    &_active,
    &.active {
      align-items: center;
      background: transparent;
      display: flex;

      // reset left icon margin
      .IconWrapper,
      .icon.icon {
        margin-right: 0;
      }

      &::after {
        content: '\ea11'; // checkmark icon

        // Based on IcoMoon's generated styles:
        font: initial;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: ReadMe-Icons !important;
        font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-variant-ligatures: discretionary-ligatures;
        letter-spacing: 0;
        line-height: 1;
        margin-left: auto;
        opacity: 0.5;
        padding-left: var(--md);
        text-transform: none;
      }
    }

    &_link {
      background: none;
      border: 0;
      cursor: pointer;
      font-weight: var(--font-weight);
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;

      &:focus {
        outline: 0;
      }

      &[aria-selected='true'],
      &:not(.Menu-Item_link_disabled):hover,
      &:not(.Menu-Item_link_disabled):focus-visible,
      &:not(.Menu-Item_link_disabled).selected {
        background: var(--Menu-background-hover);
        color: var(--Menu-item);
      }

      &:not(.Menu-Item_link_disabled):not(.Menu-Item_arrow):active {
        background: var(--Menu-background-active);
      }

      &_disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .IconWrapper,
      > .fa,
      > [class*='icon-'] {
        flex: 0 0 auto;
        font-size: 14px;
        opacity: 0.5;
        width: 14px;

        &:first-child {
          margin-right: var(--sm);
        }
      }

      .fa-blank::before {
        color: transparent;
        content: '\f111';
      }
    }

    &_external .icon-arrow-up-right {
      flex: 0 0;
      margin-left: auto;
      opacity: 0.5;
    }

    &_img {
      border-radius: var(--border-radius);
      display: block;

      &__border {
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    &_description {
      align-items: flex-start;

      .Icon {
        margin-top: 0.25em;
      }
    }

    &_arrow {
      padding-right: 30px;

      &::after {
        content: '\ea10';
        display: block;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: ReadMe-Icons !important;
        font-size: var(--icon-md);
        opacity: 0.5;
        position: absolute;
        right: 8px;
        top: 4px;
      }

      &:focus > .Menu-Item-SubMenu,
      &:hover > .Menu-Item-SubMenu {
        opacity: 1;
        pointer-events: auto;
        transform: none;
      }
    }

    &_blue {
      --Menu-background-hover: rgba(var(--blue-rgb), 0.05);
      --Menu-background-active: rgba(var(--blue-rgb), 0.1);
      --Menu-item: var(--blue);
    }

    &_green {
      --Menu-background-hover: rgba(var(--green-rgb), 0.05);
      --Menu-background-active: rgba(var(--green-rgb), 0.1);
      --Menu-item: var(--green);
    }

    &_purple {
      --Menu-background-hover: rgba(var(--purple-rgb), 0.05);
      --Menu-background-active: rgba(var(--purple-rgb), 0.1);
      --Menu-item: var(--purple);
    }

    &_red {
      --Menu-background-hover: rgba(var(--red-rgb), 0.05);
      --Menu-background-active: rgba(var(--red-rgb), 0.1);
      --Menu-item: var(--red);
    }

    &_yellow {
      --Menu-background-hover: rgba(var(--yellow-rgb), 0.075);
      --Menu-background-active: rgba(var(--yellow-rgb), 0.15);
      --Menu-item: var(--yellow);
    }

    &-check {
      margin-left: var(--md);
    }

    &-description {
      color: var(--Menu-description-color);
      font-size: 12px;
      font-weight: var(--font-weight-normal);
      line-height: 1.2;
    }

    @at-root a#{&} {
      text-decoration: none;
    }
  }

  // scoped overrides
  .Dropdown-toggle:focus {
    outline: 0;

    .Menu-Item_link:not(.Menu-Item_link_disabled) {
      background: var(--gray100);
    }
  }

  .Dropdown-content {
    border: 0;
    position: relative;
    top: -6px;
    transition:
      opacity 0.15s cubic-bezier(0.16, 1, 0.3, 1),
      transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .Dropdown-content_closed {
    transform: scale(0.95);
    visibility: hidden;
  }

  .Dropdown-content_opened {
    transform: none;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    > * {
      position: relative;
    }
  }
}

// adding css shapes to make the cursor experience better
// has to be outside of the primary .Menu class for parent selectors
/* stylelint-disable-next-line no-descending-specificity */
.Dropdown-content {
  $menu-height: 25px;
  $menu-offset: $menu-height + $xs * 2; // Dropdown padding + menu padding
  $menu-width: $menu-height * 1.5;

  [data-placement='right-start'] & {
    left: -8px;
  }

  [data-placement='left-start'] & {
    right: -8px;
  }

  &::before {
    height: $menu-height;
    top: $menu-offset;
    width: $menu-width;

    [data-placement='right-start'] & {
      left: $menu-width * -1 + $xs;
    }

    [data-placement='left-start'] & {
      right: $menu-width * -1 + $xs;
    }
  }

  &::after {
    height: $menu-height * 2;
    top: $menu-offset;
    width: $menu-width * 0.5;

    [data-placement='right-start'] & {
      left: $menu-width * 0.5 * -1 + $xs;
    }

    [data-placement='left-start'] & {
      right: $menu-width * 0.5 * -1 + $xs;
    }
  }

  &::before,
  &::after {
    $menu-limit: $menu-offset + $xs * 2;

    content: '';
    display: block;
    max-height: calc(100% - $menu-limit);
    position: absolute;
    z-index: -1;
  }
}
