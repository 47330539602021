@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

:global(.reference-redesign) {
  &.Nav {
    @include dark-mode {
      color-scheme: dark;
    }

    box-shadow: 1px 0 0 var(--color-border-default);
    display: flex;
    flex: 0 0 var(--hub-sidebar-width);
    flex-direction: column;
    max-height: calc(100vh - var(--SuperHub-top-nav-height, 0px));
    overflow-y: auto;
    position: sticky;
    top: 0;

    &:global(.rm-Sidebar_guides) :global(.rm-Sidebar) {
      padding-top: $md;
    }

    // don’t hide the mobile or guides nav (see below)
    &:not(:global(.mobile)):not(:global(.rm-Sidebar_guides)) {
      @media (max-width: $ipad-pro-10-landscape) {
        display: none;
      }
    }

    // hide guides at a smaller size
    &:global(.rm-Sidebar_guides) {
      @media (max-width: $ipad-mini-portrait) {
        display: none;
      }
    }
  }
}

:global(.reference-redesign) {
  --Sidebar-indent: 15px;

  .Sidebar {
    --Sidebar-link-hover-background: var(--gray100);
    --Sidebar-logo: var(--gray80);

    @include dark-mode {
      --Sidebar-link-hover-background: var(--gray15);
      --Sidebar-logo: var(--gray30);
    }

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: $xs;

    &-heading,
    &-link {
      color: var(--color-text-muted);
      font-family: var(--font-family);
      word-break: break-word;
    }

    &-heading {
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      margin: $xs 0;
      padding-left: var(--Sidebar-indent);
      text-transform: uppercase;
    }

    &-listWrapper {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;

      + .Sidebar-listWrapper {
        margin-top: $md;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0 0 $xs;
      padding: 0;

      .Sidebar-list .Sidebar-link {
        padding-left: var(--Sidebar-indent);

        &_parent {
          padding-left: $xs;
        }
      }
    }

    &-item {
      margin-top: 2px;

      &_active {
        background: var(--Sidebar-link-background);
        color: var(--Sidebar-link-color);
      }

      &:not(:global(.subnav-expanded)) {
        :global(.subpages) {
          display: none;
        }
      }

      &:global(.subnav-expanded) {
        display: block;

        > .Sidebar-link .Sidebar-link-expandIcon {
          transform: rotate(90deg);
        }
      }
    }

    &-link {
      align-items: center;
      border-radius: var(--border-radius);
      color: var(--color-text-muted);
      display: flex;
      flex-flow: nowrap row;
      font-size: 14px;
      justify-content: space-between;
      padding: 3px $xs 3px var(--Sidebar-indent);
      text-decoration: none;

      &:hover,
      &:focus {
        background: var(--Sidebar-link-hover-background);
      }

      &:active,
      &:global(.active) {
        background: var(--Sidebar-link-background);
        color: var(--Sidebar-link-color);
      }

      &:focus:not(:focus-visible) {
        outline: 0;
      }

      &_parent {
        padding-left: $xs;
      }

      &_deprecated {
        flex: 0 0 auto;

        &-icon {
          align-self: flex-start;
          color: var(--color-text-minimum);
          flex: 0 0 auto;
          margin-left: $xs;
          margin-top: 3px; /* for vertical alignment to the first row of text regardless of wrapping */
        }
      }

      &_hidden {
        .Sidebar-link-text_label {
          opacity: 0.5;
        }
      }

      &_error {
        &,
        &:active,
        &:global(.active) {
          background:  rgba(var(--red-rgb), 0.1);
          color: var(--red40);
        }

        &:hover,
        &:focus {
          background: rgba(var(--red-rgb), 0.15);
        }
      }

      &-pageIcon,
      &-expandIcon,
      &-externalIcon {
        align-items: center;
        color: currentcolor;
        cursor: pointer;
        display: inline-flex;
        opacity: 0.5;
      }

      &-pageIconCustom {
        display: flex;
        margin-right: var(--sm);

        :global(.rm-UserAvatar) {
          font-size: 8px;
          height: var(--icon-md);
          width: var(--icon-md);
        }
      }

      &-buttonWrapper {
        background: none;
        border: 0;
        color: inherit;
        display: inherit;
        padding: 0;
      }

      &-pageIcon {
        font-size: 16px;
        margin-right: $sm;
      }

      &-expandIcon {
        font-size: 14px;
        justify-content: flex-end;
        transform-origin: 3px 7px; /* rafe, look away */
        transition: transform var(--transition-fast) var(--transition-timing);
        width: 10px;

        &:hover,
        &:active,
        &:focus {
          opacity: 0.75;
        }
      }

      &-externalIcon {
        font-size: var(--icon-md);
        justify-content: center;
        width: var(--icon-md);
      }

      &-text {
        align-items: center;
        display: flex;
        flex: 1 1 0;
        justify-content: flex-start;
        min-width: 0;

        &_label {
          flex: 1 1 auto;
          min-width: inherit;
        }
      }
    }

    &-method {
      flex: 0 0 auto;
      margin-left: $xs;
      white-space: nowrap;

      &-container {
        align-self: flex-start;
        display: flex;
        margin-left: $xs;
        margin-top: 3px; /* for vertical alignment to the first row of text regardless of wrapping */
      }
    }

    :global(.rm-Sidebar-list .rm-Sidebar-list) {
      margin-left: var(--Sidebar-indent);
    }
  }
}

/* Mobile Sidebar Flyout View
 * @see: Hub/Header/components/mobile/Subnav.jsx
 */
@media (max-width: $ipad-mini-portrait) {
  :global(#hub-sidebar).Sidebar_flyout,
  :global(#reference-sidebar).Sidebar_flyout {
    --offsetX: 60px;
    --offsetY: 40px;

    background: var(--color-bg-page);
    border: 0 solid transparent;
    border-radius: var(--border-radius);
    box-shadow:
      0 0 0 100vw rgba(0, 0, 0, 0.125),
      0 0 15px -3px rgba(0, 0, 0, 0.33);
    display: block;
    left: calc(var(--offsetX) / 2);
    max-height: calc(100dvh - var(--offsetY));
    max-width: 333px;
    position: fixed;
    top: calc(var(--offsetY) / 2);
    transform: scale(1);
    transform-origin: left calc(var(--offsetY));
    transition: var(--transition-slow);
    width: calc(100vw - var(--offsetX));
    z-index: 200;

    > :global(.rm-Sidebar) {
      padding: var(--sm) var(--sm) 0;
    }

    body:not(:global(.showSidebarFlyout)) & {
      opacity: 0;
      pointer-events: none;
      transform: translate(calc(-1 * (var(--offsetX) / 4)), 0) scale(0.88);
    }

    @media (max-width: 400px) {
      transform-origin: center top;

      body:not(:global(.showSidebarFlyout)) & {
        transform: scale(0.88);
      }
    }
  }

  :global {
    .rm-Container:has(> #hub-sidebar, > #reference-sidebar)::before {
      background: var(--Modal-backdrop);
      content: '';
      height: 100vh; // fallback until dynamic viewport units are supported everywhere
      height: 100dvh;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: var(--transition-slow);
      width: 100vw;
      z-index: 200;

      :global(.showSidebarFlyout) & {
        opacity: 1;
        pointer-events: auto;
      }
    }

    body.showSidebarFlyout {
      height: 100vh;
      max-height: 100dvh;
      max-width: 100dvw;
      overflow: hidden;
      position: fixed;
      width: 100vw;
    }
  }
}
