@import '~@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

@mixin styles {
  p,
  :global(.p) {
    color: var(--color-text-default);
    font-size: 15px;
    font-weight: var(--font-weight-normal);
    margin-block-end: 0.25em;
    margin-block-start: 0.25em;
    padding-block-end: 0.125em;
    padding-block-start: 0.125em;
  }

  a.link {
    cursor: pointer;
  }

  li > p {
    margin: 0;
    padding: 0;
  }

  ol,
  li,
  ul {
    font-size: 15px;
    line-height: 22.5px;

    div:not(:first-child) > li {
      margin-top: 3.75px;
    }
  }

  :global(blockquote:not(.callout) > p) {
    color: var(--color-text-minimum);
  }

  ::selection {
    @include dark-mode {
      background-color: scale-color(get(color 'blue'), $saturation: -70%, $lightness: 20%);
    }

    background-color: scale-color(get(color 'blue'), $saturation: -30%, $lightness: 80%);
  }
}

.MarkdownEditor {
  @include styles;
}

.Container {
  cursor: text;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &_Button {
    background-color: transparent;
    border: 0;
    cursor: text;
    flex: 1;
    min-height: var(--clickable-area-height);
    width: 100%;
  }
}

.selected,
tr.selected {
  ::selection {
    background-color: transparent;
  }
}

.selected {
  background-color: scale-color(get(color 'blue'), $saturation: -30%, $lightness: 80%) !important;

  .selected {
    background-color: transparent !important;
  }
}

thead tr.selected {
  background-color: scale-color(get(color 'blue'), $saturation: -35%, $lightness: 75%) !important;
}

.SelectionWrapper {
  margin: auto;
  position: relative;

  &-overlay {
    background-color: var(--blue);
    height: 100%;
    opacity: 0.2;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &_selected {
    .selected {
      background-color: transparent;
    }
  }

  &-inline {
    display: inline-block;
  }

  &-image {
    margin-left: 0;
    margin-right: auto;

    &-align {
      &-center {
        margin: auto;
      }

      &-right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-embed {
    &:hover {
      cursor: pointer;
    }

    iframe {
      display: flex;
      margin: auto;
      max-width: 100%;
    }
  }

  &-jsfiddle {
    iframe {
      width: 100%;
    }
  }

  &-pdf {
    iframe {
      width: 100%;
    }
  }

  &-youtube {
    aspect-ratio: 16/9;

    iframe {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  // handles Code and Html block CodeMirror lines selection
  :global {
    .CodeMirror-line::selection,
    .CodeMirror-line > span::selection,
    .CodeMirror-line > span > span::selection {
      background: transparent;
    }
  }
}
