@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.FeaturePopover {
  --FeaturePopover-bg: var(--color-bg-default);
  --FeaturePopover-border: #{rgba(black, 0.05)};
  --FeaturePopover-border-shadow: none;
  --FeaturePopover-title-color: var(--color-text-default);
  --FeaturePopover-description-color: var(--color-text-minimum);
  --FeaturePopover-progress-color: var(--blue);
  --FeaturePopover-progress-glow: rgba(var(--blue-rgb), 0.15);
  --FeaturePopover-progress-track: rgba(var(--blue-rgb), 0.05);
  --FeaturePopover-icon-color: var(--gray20);

  @mixin popover-dark-mode {
    --FeaturePopover-bg: var(--gray0);
    --FeaturePopover-border: #{rgba(white, 0.05)};
    --FeaturePopover-border-shadow: var(--color-bg-hover);
    --FeaturePopover-title-color: var(--white);
    --FeaturePopover-description-color: rgba(255, 255, 255, 0.5);
    --FeaturePopover-progress-color: var(--blue60);
    --FeaturePopover-progress-glow: rgba(var(--blue-rgb), 0.25);
    --FeaturePopover-progress-track: rgba(var(--blue-rgb), 0.1);
    --FeaturePopover-icon-color: rgba(255, 255, 255, 0.5);
  }

  @include dark-mode {
    @include popover-dark-mode;
  }

  border-radius: var(--border-radius-lg);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  outline: 0;
  overflow: visible;
  position: relative;
  transition: background var(--transition-fast) var(--transition-timing),
              box-shadow var(--transition-fast) var(--transition-timing);
  width: 180px;

  &[data-theme='light'] {
    backdrop-filter: blur(20px);
    background: var(--FeaturePopover-bg);
    border: 1px solid var(--FeaturePopover-border);
    box-shadow: var(--box-shadow-menu-light);
  }

  &[data-theme='dark'] {
    @include popover-dark-mode;

    backdrop-filter: blur(20px);
    background: var(--FeaturePopover-bg);
    border: 1px solid var(--FeaturePopover-border);
    box-shadow: var(--box-shadow-menu);
  }

  &[data-theme='glass'] {
    --FeaturePopover-bg: rgba(255, 255, 255, 0.075);
    --FeaturePopover-progress-track: rgba(255, 255, 255, 0.15);
    --FeaturePopover-progress-color: rgba(255, 255, 255, 0.7);
    --FeaturePopover-progress-glow: rgba(255, 255, 255, 0.2);
    --FeaturePopover-description-color: rgba(255, 255, 255, 0.7);
    --FeaturePopover-icon-color: rgba(255, 255, 255, 0.5);
    --FeaturePopover-title-color: white;

    backdrop-filter: blur(10px);
    background: var(--FeaturePopover-bg);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.05),
      0 2px 5px rgba(0, 0, 0, 0.02),
      0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  &-content {
    align-items: flex-start;
    box-sizing: border-box;
    color: var(--FeaturePopover-color);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-weight);
    outline: none;
    padding: 7.5px var(--sm);
    position: relative;
    user-select: none;
    width: 100%;
  }

  &-header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--sm);
    margin-bottom: var(--xs);
    width: 100%;

    &-content {
      width: 100%;
    }
  }

  &-icon {
    color: var(--FeaturePopover-icon-color);
  }


  &-title {
    color: var(--FeaturePopover-title-color);
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    margin: 0;
  }

  &-description {
    color: var(--FeaturePopover-description-color);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    line-height: 1.2;
    margin: 0;
  }

  &-progress {
    background: var(--FeaturePopover-progress-track);
    border-radius: 4px;
    height: 3px;
    margin-bottom: var(--sm);
    margin-top: var(--sm);
    position: relative;
    width: 100%;
  }

  &-progressBar {
    background: linear-gradient(
      to right,
      var(--FeaturePopover-progress-color),
      color-mix(in srgb, var(--FeaturePopover-progress-color) 80%, transparent)
    );
    border-radius: inherit;
    box-shadow:
      0 0 8px var(--FeaturePopover-progress-glow),
      0 0 15px var(--FeaturePopover-progress-glow),
      inset 0 1px 1px rgba(255, 255, 255, 0.3);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &-spinner {
    flex-shrink: 0;
    position: relative;

    &-label {
      --feature-popover-play-state: running;

      height: 1.4em;
      position: relative;
      width: 1.4em;
    }

    &-svg {
      animation: rotate 2s linear infinite var(--feature-popover-play-state);
      height: 1.4em;
      position: absolute;
      width: 1.4em;
      z-index: 1;

      @media (prefers-reduced-motion) {
        animation: none;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      &_blue {
        fill: var(--blue);
        left: 0;
        right: 0;
        z-index: 1;
      }

      &_green {
        bottom: -0.06em;
        fill: var(--green);
        left: -0.06em;
        z-index: 0;
      }

      &_yellow {
        bottom: -0.12em;
        fill: var(--yellow);
        left: -0.12em;
        z-index: -1;
      }

      &_red {
        bottom: -0.18em;
        fill: var(--red);
        left: -0.18em;
        z-index: -2;
      }
    }

    &-text {
      align-items: center;
      color: white;
      display: flex;
      font-family: var(--font-body);
      font-size: 0.38em;
      font-weight: 800;
      inset: 0;
      justify-content: center;
      margin: auto;
      position: absolute;
      text-align: center;
      transform: rotate(-20deg) translateX(0.05em);
      z-index: 2;
    }
  }

  &-divider {
    border: 0;
    border-top: 1px solid var(--FeaturePopover-border);
    margin: var(--sm) 0;
    width: 100%;
  }

  &-emoji-animation {
    animation: fly-and-fade 1s ease-out forwards;
    font-size: 20px;
    pointer-events: none;
    position: fixed;
    user-select: none;
    z-index: 9999;
  }

  &-score-popup {
    animation: score-float 1s ease-out forwards;
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    opacity: 0.9;
    pointer-events: none;
    position: fixed;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    user-select: none;
    z-index: 9999;


    // we need to override the colors since these popup are being added dynamically
    &[data-theme='glass'] {
      color: var(--white);
    }

    &[data-theme='dark'] {
      color: var(--white);
    }

    &[data-theme='light'] {
      color: var(--color-text-default);
    }
  }

  @keyframes score-float {
    0% {
      opacity: 1;
      transform: translateY(-8px);
    }

    100% {
      opacity: 0;
      transform: translateY(-28px);
    }
  }

  @keyframes fly-and-fade {
    0% {
      opacity: 1;
      transform: translate(0, 0) rotate(0deg) scale(1);
    }

    100% {
      opacity: 0;
      transform: translate(var(--x-offset, 100px), var(--y-offset, -100px)) rotate(var(--spin, 45deg)) scale(0.5);
    }
  }

  :global(.tippy-content) {
    padding: 0;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    > * {
      position: relative;
    }
  }

  &-reaction-count {
    color: var(--FeaturePopover-title-color);
    font-size: 11px;
    font-weight: var(--font-weight-semibold);
    line-height: 1;
    margin-top: 2px; // to make it look aligned with the emoji
    min-width: 16px;
    opacity: 0.5;
    padding: 3px 6px;
    text-align: center;
  }

  &-reaction-buttons {
    width: 100%;
  }

  &-sound-button {
    color: var(--FeaturePopover-description-color);
    opacity: 0.5;
    transition: opacity 150ms ease;

    &:hover {
      opacity: 1;
    }
  }

  // helps with movement on appear
  &-sound-button-container {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }
}
